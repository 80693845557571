<template>
    <v-form class="searchTransectionsFrm" ref="searchTransectionsFrm">

        <v-row class="justify-start px-5">
            <v-col cols="12" class="mt-5pt-0 pb-1 px-9 text-left">
                <span class="font-weight-medium">Filtros</span>
            </v-col>
            <v-col cols="3" class="pl-0 pr-3 pt-0">
                <v-menu v-model="start_dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field class="caption" prepend-icon="filter_alt" outlined dense clearable
                            v-model="start_dateFormated" label="Data da transação" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="start_date" @input="start_dateMenu = false"></v-date-picker>
                </v-menu>
            </v-col>
            <!-- <v-col cols="2" class="pl-2 pr-3">
                    <v-menu v-model="end_dateMenu" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field outlined dense clearable v-model="end_dateFormated" label="Data final" readonly
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker no-title v-model="end_date" @input="end_dateMenu = false"></v-date-picker>
                    </v-menu>
                </v-col> -->
            <v-col cols="12" md="2" class="pt-0">
                <v-text-field outlined dense v-model="merchant_order" label="Nº do pedido" required></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="pt-0">
                <v-text-field outlined dense v-model="transaction_code" label="Código de transação"
                    required></v-text-field>
            </v-col>
            <v-col class="d-flex pt-0" cols="12" sm="3">
                <v-select clearable outlined dense :items="transactionStatus" v-model="status"
                    label="Status"></v-select>
            </v-col>
            <v-col class="py-0" cols="1">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="sendForm()" v-bind="attrs" v-on="on" class="mt-1" fab dark x-small
                            color="blue-grey darken-1">
                            <v-icon dark x-small> search </v-icon>
                        </v-btn>
                    </template>
                    <span>Procurar</span>
                </v-tooltip>
            </v-col>
        </v-row>

    </v-form>
</template>

<script>
//   "start_date":"",
// 	"end_date":"",
// 	"status":"Confirmado",
// 	"merchant_order":"",         (Label para esse campo 'Pedido nro.')
// 	"app":"cielo"
import * as moment from "moment";
export default {
    computed: {
        start_dateFormated: {
            get: function () {
                let date = moment(this.start_date).format("DD/MM/YYYY");
                return date == "Invalid date" ? null : date;
            },
            // setter
            set: function (newValue) {
                if (!newValue) {
                    this.start_date = "";
                }
                let date = moment(this.start_date).format("DD/MM/YYYY");
                if (date == "Invalid date") {
                    return null;
                }
            },
        },
        end_dateFormated: {
            get: function () {
                let date = moment(this.end_date).format("DD/MM/YYYY");
                return date == "Invalid date" ? null : date;
            },
            // setter
            set: function (newValue) {
                if (!newValue) {
                    this.end_date = "";
                }
                let date = moment(this.end_date).format("DD/MM/YYYY");
                if (date == "Invalid date") {
                    return null;
                }
            },
        },
    },
    watch: {
        creditCardTransectionsDialog(v) {
            if (!v) {
                this.reseteForm()
            }
        }
    },
    methods: {
        reseteForm() {
            this.start_date = ""
            this.end_date = ""
            this.merchant_order = ""
            this.status = ""
            this.transaction_code = ""
        },
        sendForm() {
            let data = {
                start_date: this.start_date,
                end_date: this.end_date,
                merchant_order: this.merchant_order,
                status: this.status,
                transaction_code: this.transaction_code,
            }
            this.getTransections(data)
        }
    },
    data() {
        return {
            transactionStatus: ["Não finalizado", "Autorizado", "Confirmado", "Não autorizado", "Cancelado", "Falha", "Aguardando retorno"],
            start_date: "",
            start_dateMenu: false,
            end_date: "",
            end_dateMenu: false,
            merchant_order: "",
            transaction_code: "",
            status: "",
        }
    },

    props: {
        getTransections: Function,
        creditCardTransectionsDialog: Boolean
    },
}
</script>

<style>
.searchTransectionsFrm label {
    font-size: 14px !important;
}
</style>