<template>
    <div>
        <TransectionsFIlters :getTransections="getTransections" />
        <!-- :creditCardTransectionsDialog="creditCardTransectionsDialog" -->

        <v-row class="px-8 pb-5 justify-end">
            <v-btn @click="toogleCreditCardLogDilog" class="mx-0 py-3" small color="deep-orange darken-3" dark>
                <v-icon small left> integration_instructions </v-icon>Logs
            </v-btn>
        </v-row>
        <v-data-table :items-per-page="5" :headers="headers" :items="transections" :single-expand="true"
            :expanded.sync="expanded" item-key="id" show-expand>
            <template v-slot:item.status="{ item }">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon style="cursor: pointer;" :color="formatStatus(item.status)[2]" dark v-bind="attrs"
                            v-on="on">
                            {{ formatStatus(item.status)[1] }}
                        </v-icon>
                    </template>
                    <span>{{ formatStatus(item.status)[0] }}</span>
                </v-tooltip>
            </template>
            <template v-slot:item.authorization_code="{ item }">
                <div>
                    <small class="mr-2">Aut:</small>
                    <span class="font-weight-medium">{{ item.authorization_code }}
                    </span>
                </div>
                <div>
                    <small class="mr-2">Pedido:</small>
                    <span class="font-weight-medium">{{ item.merchant_order }}
                    </span>
                </div>
            </template>
            <template v-slot:item.transaction_date="{ item }">

                <div class="">
                    <small class="mr-2">Data:</small>
                    <span class="font-weight-medium">{{ item.transaction_date.split(' ')[0] | formatDate }}
                    </span>
                </div>
                <div class="">
                    <small class="mr-2">Cod:</small>
                    <span class="font-weight-medium">{{ item.transaction_code ? item.transaction_code : '-' }}
                    </span>
                </div>
            </template>

            <template v-slot:item.value="{ item }">
                <div style="min-width: 100px;">
                    R$ {{ item.value | formatPrice }}
                </div>
            </template>


            <!-- <template v-slot:item.data-table-expand="{ item, isExpanded }">
                <v-btn :disabled="Array.isArray(item.recurrentPayment)" color="primary"
                    @click="handleExpansion(item, isExpanded)" outlined rounded x-small>
                    Recorrência
                </v-btn>
            </template> -->
            <template v-slot:item.data-table-expand="{ item, isExpanded }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :disabled="Array.isArray(item.recurrentPayment)" v-bind="attrs" v-on="on"
                            :class="[Array.isArray(item.recurrentPayment) ? 'disableBt' : '', 'recurrence btAverageSize px-1 mr-2']"
                            @click="handleExpansion(item, isExpanded)" color="primary" dark>
                            <v-icon center size="16"> today </v-icon></v-btn>
                    </template>
                    <span class="miniTtip">Recorrência</span>
                </v-tooltip>

                <v-tooltip top>
                    <!-- v-if="item.callback_history.length ? true : false" -->
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :disabled="item.callback_history.length ? false : true" v-bind="attrs" v-on="on"
                            :class="[item.callback_history.length ? '' : 'disableBt', 'callHistory btAverageSize px-1 mr-1']"
                            @click="showCallbackHistory(item.callback_history, item.transaction_code)"
                            color="indigo darken-1" dark><v-icon center size="18"> history
                            </v-icon></v-btn>
                    </template>
                    <span class="miniTtip">Histórico de callbacks</span>
                </v-tooltip>
            </template>




            <template v-slot:expanded-item="{ headers, item }">
                <td style="border-right: 3px solid #ccc !important;" class="pt-1 pb-2">
                    <h5 class="mt-1">Recorrências</h5>
                </td>
                <td class="text-left">
                    <div><span class="mr-2">Status:</span><b>{{ item.recurrentPayment.reasonMessage }}</b></div>
                </td>
                <td class="text-left">
                    <div class="text--center"><span class="mr-2">Vencimento:</span><b>{{
                        item.recurrentPayment.recurrence_day }}</b>
                    </div>
                </td>
                <td class="text-left" width="400">
                    <div><span class="mr-2">Próximo vencimento:</span><b>{{ item.recurrentPayment.nextRecurrency |
                        formatDate }}</b>
                    </div>
                </td>
                <td></td>
                <td></td>
            </template>
        </v-data-table>
        <CallbackHistoryList :transactionCode="transactionCode" :toogleCallbackHistoryList="toogleCallbackHistoryList"
            :callbacksList="callbacksList" :callbackHistoryDialog="callbackHistoryDialog" :creditCard="true" />
        <CreditCardLogs :creditCardLogDilog="creditCardLogDilog" :toogleCreditCardLogDilog="toogleCreditCardLogDilog" />
    </div>
</template>

<script>
import TransectionsFIlters from './TransectionsFIlters.vue'
import CallbackHistoryList from "../../../../../components/clients/billets/newManagerBillets/CallbackHistoryList.vue";
import CreditCardLogs from "../../../../../components/clients/billets/CreditCards/logs/CreditCardLogs.vue"
export default {
    watch: {
        operator(v, oldV) {
            if (v != oldV) {
                this.getTransections()
            }
        }
    },
    methods: {
        toogleCreditCardLogDilog() {
            this.creditCardLogDilog = !this.creditCardLogDilog

        },
        toogleCallbackHistoryList() {
            // this.callbacksList = []
            this.callbackHistoryDialog = !this.callbackHistoryDialog
        },
        showCallbackHistory(data, code) {

            this.callbacksList = []
            setTimeout(() => {
                this.callbacksList = [...data]
            }, 10);
            this.transactionCode = code
            this.toogleCallbackHistoryList()
        },
        formatStatus(status) {
            switch (status) {
                case 0:
                    return ["Não finalizado", "highlight_off", "red"];
                case 999:
                    return ["Falha na transação", "subtitles_off", "blue-grey darken-1"];
                case 1:
                    return ["Autorizado", "verified_user", "success"];
                case 2:
                    return ["Confirmado", "thumb_up_alt", "primary"];
                case 3:
                    return ["Não autorizado", "announcement", "red darken-3"];
                case 4:
                    return ["Mudança status no pagamento recorrênte", "currency_exchange", "green darken-2"];
                case 12:
                    return ["Aguardando Retorno", "hourglass_top", "warning"];
                default:
                    return ["Cancelado", "block", "grey darken-1"];
            }
        },
        getTransections(data) {
            let payload = { web: true, app: "cielo", ...data }
            var obj = {
                url: "/api/v1/creditcard/transaction/getAll",
                query: payload,
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.transections = [...response.data];
            });
        },
        handleExpansion(item, state) {
            // console.log(item, state);
            this.expanded = [];
            const itemIndex = this.expanded.indexOf(item);
            state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
        },
    },
    data() {
        return {
            creditCardLogDilog: false,
            callbackHistoryDialog: false,
            callbacksList: [],
            transactionCode: "",
            expanded: [],
            transections: [],
            expanded: [],
            transections: [],
            headers: [
                {
                    text: "Status",
                    value: "status",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Autorização/Pedido",
                    value: "authorization_code",
                    align: "left",
                    sortable: false,
                },
                // {
                //     text: "Número do pedido",
                //     value: "merchant_order",
                //     align: "center",
                //     sortable: false,
                // },
                {
                    text: "Data/Transaction code",
                    value: "transaction_date",
                    align: "left",
                    sortable: false,
                },
                {
                    text: "Mensagem de retorno",
                    value: "returnMessage",
                    align: "left",
                    width: 250,
                    sortable: false,
                },
                {
                    text: "Valor",
                    value: "value",
                    align: "left",
                    sortable: false,
                },
                { text: 'Ações', width: 130, value: 'data-table-expand', align: "center", },
            ]
        }
    },
    props: {
        operator: String
    },
    components: {
        TransectionsFIlters,
        CallbackHistoryList,
        CreditCardLogs
    },
}
</script>

<style>
.v-data-table__expanded__content {
    box-shadow: inset 1px -3px 4px -2px rgba(102, 102, 102, 1) !important;
    background: #f2fbff !important;
}

.v-btn.v-btn--disabled.btAverageSize.disableBt.recurrence {
    background-color: #1976D2 !important;
    border-color: #1976D2 !important;
    opacity: 0.4 !important;
}

.v-btn.v-btn--disabled.btAverageSize.disableBt.callHistory {
    background-color: #3949AB !important;
    border-color: #3949AB !important;
    opacity: 0.7 !important;
}
</style>